@import "./normalize.scss";
@import "./media-queries.scss";
@import "./vendor/spinner.css";
@import "./vendor/shepherd.css";

@font-face {
  font-family: "Museo Sans";
  src: url("../assets/museo-sans-webfont/Museo-Sans-Family/MuseoSans-100.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "Museo Sans";
  src: url("../assets/museo-sans-webfont/Museo-Sans-Family/MuseoSans-500.woff") format("woff");
  font-weight: 500;
}

:root {
  --primary-blue: rgb(42, 127, 140);
  --primary-blue-opaque: rgb(42, 127, 140, 0.5);
  --secondary-blk: #292829;
  --secondary-blk-opaque: rgba(41, 40, 41, 0.8);
  --secondary-white: rgb(245, 245, 245);
  --secondary-white-opaque: rgba(245, 245, 245, 0.5);
  --accent-blue: rgb(0, 173, 174);
  --accent-blue-opaque: rgba(0, 173, 174, 0.5);
  --accent-red: rgb(233, 115, 85);
  --accent-red-opaque: rgb(233, 115, 85, 0.85);
  --accent-green: rgb(177, 198, 118);
  --accent-green-opaque: rgb(177, 198, 118, 0.8);
  --accent-darkgray: #6d6e71;
  --accent-darkgray-opaque: rgba(109, 110, 113, 0.795);
  --accent-gray: #a7a9ac;
  --accent-gray-opaque: rgba(167, 169, 172, 0.5);
  --accent-lightgray: #e6e7e8;
  --accent-lightgray-opaque: rgba(230, 231, 232, 0.5);
  --blue-added: #1a5962;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@-ms-viewport {
  width: device-width;
}

html,
body {
  height: 100%;
  font-family: "Museo Sans", sans-serif;
  font-weight: 500;
  background-color: var(--secondary-blk);
  color: var(--secondary-white);
  overflow: hidden;

  #app {
    opacity: 1;
    visibility: visible;
    transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: "Antonio", sans-serif;
}

#fullscreenToggle {
  filter: invert(1);
  z-index: 100 !important;
  &:hover {
    cursor: pointer;
  }
}

.mobile-fullscreen-cta {
  max-width: 300px;
  padding: 1rem;
}

.mobile-fullscreen-cta,
.controls {
  flex-direction: column;
  background: secondary-white-opaque;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls {
  padding: 1rem 2rem;
}

.mobile-fullscreen-cta .cta-text {
  margin-bottom: 1rem;
}

.mobile-fullscreen-cta button {
  padding: 0.5rem 1rem;
  color: var(--secondary-white);
  border: 1px solid var(--secondary-white);
  background: transparent;
}

.mobile-fullscreen-cta button:hover {
  cursor: pointer;
  background: var(--secondary-blk-opaque);
}

.preloader {
  color: #fff;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.5s linear;
  z-index: 99999;
}

#tooltip,
#tooltip-LH {
  opacity: 0;
  color: #292829;
  border-radius: 1rem;
  font-size: 12px;
  background: #fff;
  text-align: center;
  padding: 1rem 1.25rem;
  font-weight: 700;
}

.second-tour-starter #tooltip-LH[data-show] {
  opacity: 0;
}

#tooltip-LH {
  min-width: 125px;
}

#tooltip,
#tooltip-LH {
  transition: opacity 1s linear;
}

#tooltip[data-show],
#tooltip-LH[data-show] {
  opacity: 1;
}

#intro-close {
  &:hover {
    cursor: pointer;
  }
}

#intro .intro-content,
#controls .controls {
  font-size: 0.75em;
  border: 1px solid var(--secondary-blk);
  color: var(--secondary-blk);
  background: rgb(255 255 255 / 80%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

#controls .controls {
  padding: 2rem 1rem;
  max-width: 400px;
}

#intro .intro-content {
  max-width: 100%;
}

.controls-content {
  display: flex;
  flex-direction: column;
}

.controls-header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
}

.controls-icons {
  display: flex;
  flex-direction: row;
}

.controls-section,
.controls-section-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5em 1rem;
}

.controls-section-top span {
  max-width: 18ch;
}

.controls-section img,
.controls-section-top img {
  margin-bottom: 0.5rem;
}

.controls-explore {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls-h1 {
  font-size: $h1-font-size;
  align-self: center;
  justify-self: center;
  font-family: "antonio", sans-serif;
  margin: 0 0 1.25rem 0;
  position: relative;
  &.spoon {
    color: #5e4316;
    font-size: $h2-font-size;
    padding: 1.25rem 0 0rem 0;
  }
}

.controls-header .controls-menu-close {
  justify-self: flex-end;
  &:hover {
    cursor: pointer;
  }
}

.controls-left,
.controls-right {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.75rem;
}

.controls-left > *,
.controls-right > * {
  margin-bottom: 1rem;
}

#intro .intro-header,
.help-header {
  border-radius: 0.5rem 0.5rem 0 0;
  width: 100%;
  max-height: 40px;
  padding: 10px 5px;
  -ms-flex-item-align: start;
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#intro .intro-header .info-hotspot-close-wrapper {
  position: relative;
  align-self: center;
}

#intro-heading {
  display: flex;
  text-align: center;
  margin: 1rem 2rem;
}

#intro-heading .intro-h1 {
  font-size: $h1-font-size;
  margin: 0 1rem;
  line-height: $h1-font-size;
  font-weight: 500;
  max-width: 22ch;
  justify-self: flex-start;
}

#intro-body {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
  font-size: 1rem;
  max-width: 26ch;
  > p {
    padding-bottom: 1rem;
  }
}

#intro-buttons {
  display: flex;
  width: 100%;
  height: auto;
}

#intro-buttons .tour-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--secondary-blk-opaque);
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  width: 100%;
  padding: 1.25rem 1rem;
  color: inherit;
  font-size: 1rem;
  font-weight: 500;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: 0.1s background-color linear;
  -o-transition: 0.1s background-color linear;
  transition: 0.1s background-color linear;
  text-align: center;
}

.tour-btn:hover {
  cursor: pointer;
  background-color: var(--accent-gray-opaque);
}

#intro-buttons .cancel {
  border-bottom-left-radius: 0.5rem;
}

#intro-buttons .confirm {
  border-left: 1px solid var(--secondary-blk);
  border-bottom-right-radius: 0.5rem;
}

.loader-inner {
  margin-top: 3.5rem;
}

.loader-inner div {
  width: 10px;
  height: 10px;
  margin: 0px;
}

.preload-text {
  margin-top: 3.25rem;
  font-weight: lighter;
  font-size: 1rem;
}

.preload-text::after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

#pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#title-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.45rem;

  .title-bar-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;

    .pc-logo {
      position: relative;
      bottom: 5px;
      left: 10px;
      display: block;
      max-width: 140px;
      height: auto;
      filter: invert(1);
    }

    .menu-icon-toggle {
      cursor: pointer;
      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}

#fullscreenToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

body.fullscreen-enabled #fullscreenToggle {
  display: block;
}

#fullscreenToggle .icon {
  max-width: 30px;
  max-height: 30px;
  filter: invert(1);
  opacity: 0.8;
}

#fullscreenToggle .icon.on {
  display: none;
}

#fullscreenToggle .icon.off {
  display: none;
}

#fullscreenToggle.enabled .icon.on {
  display: block;
}

#fullscreenToggle.enabled .icon.off {
  display: none;
}

/* Link hotspot */

.link-hotspot {
  transition: opacity 0.2s;
}

.no-touch .link-hotspot:hover {
  opacity: 1;
}

.link-hotspot-icon {
  background-color: var(--secondary-blk-opaque);
  border: 2px solid var(--accent-blue);
  width: 40px;
  height: 40px;
  border-radius: 2rem;
  /* margin-right: -1px; */
  position: relative;
  cursor: pointer;
  top: -1rem;
}

.link-hotspot-tooltip {
  display: none;
}

.link-hotspot-tooltip[data-show] {
  display: block;
}

.no-touch .link-hotspot:hover .link-hotspot-tooltip {
  opacity: 1;
  transform: translateY(0);
}

/* Prevent tooltip from triggering */
.link-hotspot-tooltip {
  pointer-events: none;
}

.no-touch .link-hotspot:hover .link-hotspot-tooltip {
  pointer-events: all;
}

/* Fallback mode without pointer-events (IE8-10) */
.tooltip-fallback .link-hotspot-tooltip {
  display: none;
}

.no-touch .tooltip-fallback .link-hotspot:hover .link-hotspot-tooltip {
  display: block;
}

/* Info hotspot */
.info-hotspot .info-hotspot-content,
.info-hotspot .info-hotspot-close-wrapper {
  display: none;
}

.info-hotspot {
  opacity: 0.9;
  transition: opacity 0.2s 0.2s;
}

.info-hotspot:hover {
  opacity: 1;
  transition: opacity 0.2s;
}

.info-hotspot.visible {
  opacity: 1;
}

.info-hotspot .info-hotspot-header {
  transform: translate(-1rem, -1rem);
  display: flex;
  width: 50px;
  height: 50px;
  border: 2px solid var(--secondary-white);
  border-radius: 25px;
  cursor: pointer;
  transition: width 0.3s ease-in-out 0.5s, border-radius 0.3s ease-in-out 0.5s, background 1s ease-in-out;
  color: var(--secondary-white);
  background: var(--secondary-blk);

  // Golden spoon icons
  &.hidden-icon {
    opacity: 0;
  }
}

.desktop .info-hotspot .info-hotspot-header:hover {
  width: 165px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out, background 0.5s ease-in-out;
}

.info-hotspot .info-hotspot-icon-wrapper {
  width: 36px;
  height: auto;
}

.info-hotspot .info-hotspot-icon {
  width: 36px;
  border-radius: 50%;
  margin: 5px;
}

.info-hotspot .info-hotspot-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  width: 0;
  height: 100%;
  padding: 0;
  overflow: hidden;
  transition: width 0s 0.4s, padding 0s 0.4s;
}

.desktop .info-hotspot .info-hotspot-header:hover .info-hotspot-title-wrapper {
  width: 100%;
  transition: width 0s 0.4s, padding 0s 0.4s;
}

.info-hotspot .info-hotspot-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 11px 2px 11px;
  font-size: 12px;
    user-select: none;
  font-family: "Antonio", sans-serif;
}

.return-to-view {
  &.hidden {
    display: none;
  }
  position: absolute;
  bottom: 1em;
  right: 1em;
  background-color: var(--secondary-white-opaque);
  border: 2px solid var(--secondary-blk);
  border-radius: 1rem;
  box-shadow: var(--secondary-blk) 4px 4px 0 0;
  color: var(--secondary-blk);
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 10px;
  padding: 0 20px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  z-index: 999999!important;
  &:hover {
    background-color: var(--secondary-white);
  }
  &:active {
    box-shadow: var(--secondary-blk) 2px 2px 0 0;
    transform: translate(2px, 2px);
  }
}

@media (min-width: 768px) {
  .return-to-view {
    min-width: 120px;
    padding: 0 25px;
    line-height: 34px;
    font-size: 12px;
  }
}

.dev-only {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10000;
  height: auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  form {
    position: relative;
    display: flex;

    button {
      width: 100%;
    }
  }
}

.nav-modal-container {
  opacity: 0;
  visibility: hidden;

  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9999;
  margin: 0 auto;

  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.nav-modal-wrapper {
  color: var(--secondary-blk);
  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  margin: 2rem;
  position: relative;

  & > .btn-close {
    position: absolute;
    right: -1.25rem;
    top: -1.25rem;
    width: 3rem;
    height: 3rem;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 100%;
    background-color: #292829;
    z-index: 4;
    color: #fff;
    line-height: 3rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }
}

.nav-modal-inner ul {
  border: 1px solid #292829;
  border-radius: 1rem;
  margin: 2rem 0 0 0;
  padding: 0;
  overflow: hidden;

  li {
    list-style: none;
  }

  a:not(.info-hotspot-content--close) {
    display: block;
    padding: 1rem;
    text-align: center;
  }

  a:hover,
  a:focus {
    color: white;
    background-color: #292829;
  }

  li:not(:last-of-type) {
    border-bottom: 1px solid #292829;
  }
}
