.shepherd-element {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  outline: none;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  z-index: 9999;
}

.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.shepherd-element,
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
  box-sizing: border-box;
}

.shepherd-arrow,
.shepherd-arrow:before {
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: -1;
}

.shepherd-arrow:before {
  content: "";
  transform: rotate(45deg);
  background: #fff;
}

.shepherd-element[data-popper-placement^="top"] > .shepherd-arrow {
  bottom: -8px;
}

.shepherd-element[data-popper-placement^="bottom"] > .shepherd-arrow {
  top: -8px;
}

.shepherd-element[data-popper-placement^="left"] > .shepherd-arrow {
  right: -8px;
}

.shepherd-element[data-popper-placement^="right"] > .shepherd-arrow {
  left: -8px;
}

.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}

.shepherd-element.shepherd-has-title[data-popper-placement^="bottom"]
  > .shepherd-arrow:before {
  background-color: #e6e6e6;
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-modal-overlay-container {
  -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
  filter: alpha(opacity=50);
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100%;
  z-index: 9997;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0.5;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}

.shepherd-content {
  border-radius: 5px;
  outline: none;
  padding: 0;
}

.shepherd-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 0 0.75rem 0.75rem;
}

.shepherd-footer .shepherd-button:last-child {
  margin-right: 0;
}

.shepherd-header {
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  padding: 0.75rem 0.75rem 0;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: #e6e6e6;
  padding: 1em;
}

.shepherd-text {
  color: rgba(0, 0, 0, 0.75);
  font-size: 1.2rem;
  line-height: 1.2em;
}

.shepherd-text p {
  margin-top: 0;
}

.shepherd-text p:last-child {
  margin-bottom: 0;
}

.shepherd-button {
  background: #3288e6;
  border: 0;
  border-radius: 3px;
  color: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0.5rem 1.5rem;
  transition: all 0.5s ease;
}

.shepherd-button:not(:disabled):hover {
  background: #196fcc;
  color: hsla(0, 0%, 100%, 0.75);
}

.shepherd-button.shepherd-button-secondary {
  background: #f1f2f3;
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-button.shepherd-button-secondary:not(:disabled):hover {
  background: #d6d9db;
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-button:disabled {
  cursor: not-allowed;
}

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: hsla(0, 0%, 50.2%, 0.75);
  font-size: 2em;
  cursor: pointer;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color 0.5s ease;
}

.shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: hsla(0, 0%, 50.2%, 0.75);
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-title {
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
}

#tooltip,
#tooltip-LH {
  opacity: 0;
  color: #292829;
  border-radius: 1rem;
  font-size: 12px;
  background: #fff;
  text-align: center;
  padding: 1rem 1.25rem;
  font-weight: 700;
}

.second-tour-starter #tooltip-LH[data-show] {
  opacity: 0;
}

#tooltip-LH {
  min-width: 125px;
}

#tooltip,
#tooltip-LH {
  transition: opacity 1s linear;
}

#tooltip[data-show],
#tooltip-LH[data-show] {
  opacity: 1;
}

#arrow,
#arrow::before,
#arrow-LH,
#arrow-LH::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow,
#arrow-LH {
  visibility: hidden;
}

#arrow::before,
#arrow-LH::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  transition: all 0.8s ease-out, height 0s 0s, opacity 0.8s 0s !important;
}

.shepherd-element {
  transition: opacity 0.8s, visibility 0.8s !important;
  max-width: 200px !important;
}

.shepherd-header {
  height: 40px;
  padding: 0 0.75rem !important;
}

.shepherd-cancel-icon {
  -ms-flex-item-align: center !important;
  -ms-grid-row-align: center !important;
  align-self: center !important;
}

.shepherd-text {
  padding: 0.75rem 1rem !important;
  font-size: clamp(0.85rem, 2vw, 1rem) !important;
  text-align: center;
}

.shepherd-button {
  color: #292829 !important;
  background: transparent !important;
  border: 1px solid var(--secondary-blk) !important;
  padding: 0.25rem 1rem !important;
}

.shepherd-button:hover {
  background: var(--accent-gray) !important;
}
