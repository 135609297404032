@keyframes ball-spin-fade-loader {
  50% {
    opacity: .3;
    transform: scale(.4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ball-spin-fade-loader {
  position: relative;
  top: -10px;
  left: -10px;
}

.ball-spin-fade-loader > div:first-child {
  animation: 1s linear -.96s infinite ball-spin-fade-loader;
  top: 25px;
  left: 0;
}

.ball-spin-fade-loader > div:nth-child(2) {
  animation: 1s linear -.84s infinite ball-spin-fade-loader;
  top: 17.05px;
  left: 17.05px;
}

.ball-spin-fade-loader > div:nth-child(3) {
  animation: 1s linear -.72s infinite ball-spin-fade-loader;
  top: 0;
  left: 25px;
}

.ball-spin-fade-loader > div:nth-child(4) {
  animation: 1s linear -.6s infinite ball-spin-fade-loader;
  top: -17.05px;
  left: 17.05px;
}

.ball-spin-fade-loader > div:nth-child(5) {
  animation: 1s linear -.48s infinite ball-spin-fade-loader;
  top: -25px;
  left: 0;
}

.ball-spin-fade-loader > div:nth-child(6) {
  animation: 1s linear -.36s infinite ball-spin-fade-loader;
  top: -17.05px;
  left: -17.05px;
}

.ball-spin-fade-loader > div:nth-child(7) {
  animation: 1s linear -.24s infinite ball-spin-fade-loader;
  top: 0;
  left: -25px;
}

.ball-spin-fade-loader > div:nth-child(8) {
  animation: 1s linear -.12s infinite ball-spin-fade-loader;
  top: 17.05px;
  left: -17.05px;
}

.ball-spin-fade-loader > div, .ball-spin-loader > div {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  -webkit-animation-fill-mode: both;
  position: absolute;
}

.ball-spin-fade-loader > div {
  background-color: #fff;
  margin: 2px;
  animation-fill-mode: both;
}

.shepherd-element {
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  background: #fff;
  border-radius: 5px;
  outline: none;
  transition: opacity .3s, visibility .3s;
  box-shadow: 0 1px 4px #0003;
}

.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.shepherd-element, .shepherd-element *, .shepherd-element :after, .shepherd-element :before {
  box-sizing: border-box;
}

.shepherd-arrow, .shepherd-arrow:before {
  z-index: -1;
  width: 16px;
  height: 16px;
  position: absolute;
}

.shepherd-arrow:before {
  content: "";
  background: #fff;
  transform: rotate(45deg);
}

.shepherd-element[data-popper-placement^="top"] > .shepherd-arrow {
  bottom: -8px;
}

.shepherd-element[data-popper-placement^="bottom"] > .shepherd-arrow {
  top: -8px;
}

.shepherd-element[data-popper-placement^="left"] > .shepherd-arrow {
  right: -8px;
}

.shepherd-element[data-popper-placement^="right"] > .shepherd-arrow {
  left: -8px;
}

.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}

.shepherd-element.shepherd-has-title[data-popper-placement^="bottom"] > .shepherd-arrow:before {
  background-color: #e6e6e6;
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target, .shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-modal-overlay-container {
  -ms-filter: progid:dximagetransform. microsoft. gradient. alpha(Opacity= 50);
  filter: alpha(opacity= 50);
  opacity: 0;
  pointer-events: none;
  z-index: 9997;
  width: 100%;
  height: 0;
  transition: all .3s ease-out, height 0s .3s, opacity .3s;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  opacity: .5;
  height: 100vh;
  transition: all .3s ease-out, height, opacity .3s;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}

.shepherd-content {
  border-radius: 5px;
  outline: none;
  padding: 0;
}

.shepherd-footer {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: flex-end;
  padding: 0 .75rem .75rem;
  display: flex;
}

.shepherd-footer .shepherd-button:last-child {
  margin-right: 0;
}

.shepherd-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem .75rem 0;
  line-height: 2em;
  display: flex;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: #e6e6e6;
  padding: 1em;
}

.shepherd-text {
  color: #000000bf;
  font-size: 1.2rem;
  line-height: 1.2em;
}

.shepherd-text p {
  margin-top: 0;
}

.shepherd-text p:last-child {
  margin-bottom: 0;
}

.shepherd-button {
  color: #ffffffbf;
  cursor: pointer;
  background: #3288e6;
  border: 0;
  border-radius: 3px;
  margin-right: .5rem;
  padding: .5rem 1.5rem;
  transition: all .5s;
}

.shepherd-button:not(:disabled):hover {
  color: #ffffffbf;
  background: #196fcc;
}

.shepherd-button.shepherd-button-secondary {
  color: #000000bf;
  background: #f1f2f3;
}

.shepherd-button.shepherd-button-secondary:not(:disabled):hover {
  color: #000000bf;
  background: #d6d9db;
}

.shepherd-button:disabled {
  cursor: not-allowed;
}

.shepherd-cancel-icon {
  color: #808080bf;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 2em;
  font-weight: 400;
  transition: color .5s;
}

.shepherd-cancel-icon:hover {
  color: #000000bf;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: #808080bf;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: #000000bf;
}

.shepherd-title {
  color: #000000bf;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
}

#arrow, #arrow:before, #arrow-LH, #arrow-LH:before {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
}

#arrow, #arrow-LH {
  visibility: hidden;
}

#arrow:before, #arrow-LH:before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  transition: all .8s ease-out, height, opacity .8s !important;
}

.shepherd-element {
  max-width: 200px !important;
  transition: opacity .8s, visibility .8s !important;
}

.shepherd-header {
  height: 40px;
  padding: 0 .75rem !important;
}

.shepherd-cancel-icon {
  align-self: center !important;
}

.shepherd-text {
  text-align: center;
  padding: .75rem 1rem !important;
  font-size: clamp(.85rem, 2vw, 1rem) !important;
}

.shepherd-button {
  color: #292829 !important;
  border: 1px solid var(--secondary-blk) !important;
  background: none !important;
  padding: .25rem 1rem !important;
}

.shepherd-button:hover {
  background: var(--accent-gray) !important;
}

@font-face {
  font-family: Museo Sans;
  src: url("assets/museo-sans-webfont/Museo-Sans-Family/MuseoSans-100.7b68e140.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: Museo Sans;
  src: url("assets/museo-sans-webfont/Museo-Sans-Family/MuseoSans-500.f6548c6a.woff") format("woff");
  font-weight: 500;
}

:root {
  --primary-blue: #2a7f8c;
  --primary-blue-opaque: #2a7f8c80;
  --secondary-blk: #292829;
  --secondary-blk-opaque: #292829cc;
  --secondary-white: #f5f5f5;
  --secondary-white-opaque: #f5f5f580;
  --accent-blue: #00adae;
  --accent-blue-opaque: #00adae80;
  --accent-red: #e97355;
  --accent-red-opaque: #e97355d9;
  --accent-green: #b1c676;
  --accent-green-opaque: #b1c676cc;
  --accent-darkgray: #6d6e71;
  --accent-darkgray-opaque: #6d6e71cb;
  --accent-gray: #a7a9ac;
  --accent-gray-opaque: #a7a9ac80;
  --accent-lightgray: #e6e7e8;
  --accent-lightgray-opaque: #e6e7e880;
  --blue-added: #1a5962;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  background-color: var(--secondary-blk);
  color: var(--secondary-white);
  height: 100%;
  font-family: Museo Sans, sans-serif;
  font-weight: 500;
  overflow: hidden;
}

html #app, body #app {
  opacity: 1;
  visibility: visible;
  transition: all 1s cubic-bezier(.215, .61, .355, 1);
}

html #app.hidden, body #app.hidden {
  opacity: 0;
  visibility: hidden;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-family: Antonio, sans-serif;
}

#fullscreenToggle {
  filter: invert();
  z-index: 100 !important;
}

#fullscreenToggle:hover {
  cursor: pointer;
}

.mobile-fullscreen-cta {
  max-width: 300px;
  padding: 1rem;
}

.mobile-fullscreen-cta, .controls {
  background: secondary-white-opaque;
  text-align: center;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.controls {
  padding: 1rem 2rem;
}

.mobile-fullscreen-cta .cta-text {
  margin-bottom: 1rem;
}

.mobile-fullscreen-cta button {
  color: var(--secondary-white);
  border: 1px solid var(--secondary-white);
  background: none;
  padding: .5rem 1rem;
}

.mobile-fullscreen-cta button:hover {
  cursor: pointer;
  background: var(--secondary-blk-opaque);
}

.preloader {
  color: #fff;
  opacity: 1;
  z-index: 99999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  transition: opacity .5s linear;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#tooltip, #tooltip-LH {
  opacity: 0;
  color: #292829;
  text-align: center;
  background: #fff;
  border-radius: 1rem;
  padding: 1rem 1.25rem;
  font-size: 12px;
  font-weight: 700;
}

.second-tour-starter #tooltip-LH[data-show] {
  opacity: 0;
}

#tooltip-LH {
  min-width: 125px;
}

#tooltip, #tooltip-LH {
  transition: opacity 1s linear;
}

#tooltip[data-show], #tooltip-LH[data-show] {
  opacity: 1;
}

#intro-close:hover {
  cursor: pointer;
}

#intro .intro-content, #controls .controls {
  border: 1px solid var(--secondary-blk);
  color: var(--secondary-blk);
  background: #fffc;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: .75em;
  display: flex;
}

#controls .controls {
  max-width: 400px;
  padding: 2rem 1rem;
}

#intro .intro-content {
  max-width: 100%;
}

.controls-content {
  flex-direction: column;
  display: flex;
}

.controls-header {
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.controls-icons {
  flex-direction: row;
  display: flex;
}

.controls-section, .controls-section-top {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: .5em 1rem;
  display: flex;
}

.controls-section-top span {
  max-width: 18ch;
}

.controls-section img, .controls-section-top img {
  margin-bottom: .5rem;
}

.controls-explore {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.controls-h1 {
  place-self: center;
  margin: 0 0 1.25rem;
  font-family: antonio, sans-serif;
  font-size: 25.9px;
  position: relative;
}

.controls-h1.spoon {
  color: #5e4316;
  padding: 1.25rem 0 0;
  font-size: 21px;
}

.controls-header .controls-menu-close {
  justify-self: flex-end;
}

.controls-header .controls-menu-close:hover {
  cursor: pointer;
}

.controls-left, .controls-right {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.controls-left > *, .controls-right > * {
  margin-bottom: 1rem;
}

#intro .intro-header, .help-header {
  border-radius: .5rem .5rem 0 0;
  justify-content: flex-end;
  align-self: flex-start;
  align-items: center;
  width: 100%;
  max-height: 40px;
  padding: 10px 5px;
  display: flex;
}

#intro .intro-header .info-hotspot-close-wrapper {
  align-self: center;
  position: relative;
}

#intro-heading {
  text-align: center;
  margin: 1rem 2rem;
  display: flex;
}

#intro-heading .intro-h1 {
  justify-self: flex-start;
  max-width: 22ch;
  margin: 0 1rem;
  font-size: 25.9px;
  font-weight: 500;
  line-height: 25.9px;
}

#intro-body {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  max-width: 26ch;
  padding: 1rem 0;
  font-size: 1rem;
  display: flex;
}

#intro-body > p {
  padding-bottom: 1rem;
}

#intro-buttons {
  width: 100%;
  height: auto;
  display: flex;
}

#intro-buttons .tour-btn {
  border-top: 1px solid var(--secondary-blk-opaque);
  color: inherit;
  -webkit-appearance: none;
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 1.25rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color .1s linear;
  display: flex;
  overflow: visible;
}

.tour-btn:hover {
  cursor: pointer;
  background-color: var(--accent-gray-opaque);
}

#intro-buttons .cancel {
  border-bottom-left-radius: .5rem;
}

#intro-buttons .confirm {
  border-left: 1px solid var(--secondary-blk);
  border-bottom-right-radius: .5rem;
}

.loader-inner {
  margin-top: 3.5rem;
}

.loader-inner div {
  width: 10px;
  height: 10px;
  margin: 0;
}

.preload-text {
  margin-top: 3.25rem;
  font-size: 1rem;
  font-weight: lighter;
}

.preload-text:after {
  content: " .";
  animation: 1s steps(5, end) infinite dots;
}

@keyframes dots {
  0%, 20% {
    color: #0000;
    text-shadow: .25em 0 #0000, .5em 0 #0000;
  }

  40% {
    color: #fff;
    text-shadow: .25em 0 #0000, .5em 0 #0000;
  }

  60% {
    text-shadow: .25em 0 #fff, .5em 0 #0000;
  }

  80%, 100% {
    text-shadow: .25em 0 #fff, .5em 0 #fff;
  }
}

a, a:hover, a:active, a:visited {
  color: inherit;
  text-decoration: none;
}

#pano {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

#title-bar {
  width: 100%;
  padding: .45rem;
  position: absolute;
  top: 0;
  left: 0;
}

#title-bar .title-bar-inner {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
}

#title-bar .title-bar-inner .pc-logo {
  filter: invert();
  max-width: 140px;
  height: auto;
  display: block;
  position: relative;
  bottom: 5px;
  left: 10px;
}

#title-bar .title-bar-inner .menu-icon-toggle {
  cursor: pointer;
}

#title-bar .title-bar-inner .menu-icon-toggle.hidden {
  visibility: hidden;
  opacity: 0;
}

#fullscreenToggle {
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

body.fullscreen-enabled #fullscreenToggle {
  display: block;
}

#fullscreenToggle .icon {
  filter: invert();
  opacity: .8;
  max-width: 30px;
  max-height: 30px;
}

#fullscreenToggle .icon.on, #fullscreenToggle .icon.off {
  display: none;
}

#fullscreenToggle.enabled .icon.on {
  display: block;
}

#fullscreenToggle.enabled .icon.off {
  display: none;
}

.link-hotspot {
  transition: opacity .2s;
}

.no-touch .link-hotspot:hover {
  opacity: 1;
}

.link-hotspot-icon {
  background-color: var(--secondary-blk-opaque);
  border: 2px solid var(--accent-blue);
  cursor: pointer;
  border-radius: 2rem;
  width: 40px;
  height: 40px;
  position: relative;
  top: -1rem;
}

.link-hotspot-tooltip {
  display: none;
}

.link-hotspot-tooltip[data-show] {
  display: block;
}

.no-touch .link-hotspot:hover .link-hotspot-tooltip {
  opacity: 1;
  transform: translateY(0);
}

.link-hotspot-tooltip {
  pointer-events: none;
}

.no-touch .link-hotspot:hover .link-hotspot-tooltip {
  pointer-events: all;
}

.tooltip-fallback .link-hotspot-tooltip {
  display: none;
}

.no-touch .tooltip-fallback .link-hotspot:hover .link-hotspot-tooltip {
  display: block;
}

.info-hotspot .info-hotspot-content, .info-hotspot .info-hotspot-close-wrapper {
  display: none;
}

.info-hotspot {
  opacity: .9;
  transition: opacity .2s .2s;
}

.info-hotspot:hover {
  opacity: 1;
  transition: opacity .2s;
}

.info-hotspot.visible {
  opacity: 1;
}

.info-hotspot .info-hotspot-header {
  border: 2px solid var(--secondary-white);
  cursor: pointer;
  color: var(--secondary-white);
  background: var(--secondary-blk);
  border-radius: 25px;
  width: 50px;
  height: 50px;
  transition: width .3s ease-in-out .5s, border-radius .3s ease-in-out .5s, background 1s ease-in-out;
  display: flex;
  transform: translate(-1rem, -1rem);
}

.info-hotspot .info-hotspot-header.hidden-icon {
  opacity: 0;
}

.desktop .info-hotspot .info-hotspot-header:hover {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 165px;
  transition: width .3s ease-in-out, border-radius .3s ease-in-out, background .5s ease-in-out;
}

.info-hotspot .info-hotspot-icon-wrapper {
  width: 36px;
  height: auto;
}

.info-hotspot .info-hotspot-icon {
  border-radius: 50%;
  width: 36px;
  margin: 5px;
}

.info-hotspot .info-hotspot-title-wrapper {
  justify-content: center;
  align-items: center;
  width: 0;
  height: 100%;
  padding: 0;
  transition: width 0s .4s, padding 0s .4s;
  display: flex;
  position: relative;
  top: 0;
  overflow: hidden;
}

.desktop .info-hotspot .info-hotspot-header:hover .info-hotspot-title-wrapper {
  width: 100%;
  transition: width 0s .4s, padding 0s .4s;
}

.info-hotspot .info-hotspot-title {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 11px 2px;
  font-family: Antonio, sans-serif;
  font-size: 12px;
  display: flex;
}

.return-to-view {
  background-color: var(--secondary-white-opaque);
  border: 2px solid var(--secondary-blk);
  box-shadow: var(--secondary-blk) 4px 4px 0 0;
  color: var(--secondary-blk);
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  border-radius: 1rem;
  padding: 0 20px;
  font-size: 10px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 999999 !important;
}

.return-to-view.hidden {
  display: none;
}

.return-to-view:hover {
  background-color: var(--secondary-white);
}

.return-to-view:active {
  box-shadow: var(--secondary-blk) 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (width >= 768px) {
  .return-to-view {
    min-width: 120px;
    padding: 0 25px;
    font-size: 12px;
    line-height: 34px;
  }
}

.dev-only {
  z-index: 10000;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: auto;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.dev-only form {
  display: flex;
  position: relative;
}

.dev-only form button {
  width: 100%;
}

.nav-modal-container {
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  background-color: #00000073;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transition: all 1s cubic-bezier(.215, .61, .355, 1);
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.nav-modal-container.visible {
  opacity: 1;
  visibility: visible;
}

.nav-modal-wrapper {
  color: var(--secondary-blk);
  text-align: center;
  background-color: #fff;
  border-radius: 1rem;
  margin: 2rem;
  padding: 2rem;
  position: relative;
}

.nav-modal-wrapper > .btn-close {
  z-index: 4;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #292829;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 3rem;
  text-decoration: none;
  position: absolute;
  top: -1.25rem;
  right: -1.25rem;
}

.nav-modal-inner ul {
  border: 1px solid #292829;
  border-radius: 1rem;
  margin: 2rem 0 0;
  padding: 0;
  overflow: hidden;
}

.nav-modal-inner ul li {
  list-style: none;
}

.nav-modal-inner ul a:not(.info-hotspot-content--close) {
  text-align: center;
  padding: 1rem;
  display: block;
}

.nav-modal-inner ul a:hover, .nav-modal-inner ul a:focus {
  color: #fff;
  background-color: #292829;
}

.nav-modal-inner ul li:not(:last-of-type) {
  border-bottom: 1px solid #292829;
}
/*# sourceMappingURL=index.e23dd7f9.css.map */
